import React, { Component, Fragment, CSSProperties } from 'react';
import {
    createStyles,
    WithStyles,
    withStyles,
    Zoom,
    Slide,
    Dialog,
    Button,
    DialogTitle,
    Fab,
    Typography,
    CircularProgress,
    LinearProgress,
} from '@material-ui/core';
import { connect } from 'react-redux';
import _ from 'lodash';
import Header from './Header';
import { TCurrentDocument } from '../../Features/DetailPage/@types';
import Theme from '../../Resources/Theme';
import { ODetail } from '../../Features/DetailPage/redux-config';
import PdfContent from '../../Components/PdfContent';
import LTDMetadata from './LTDMetadata';
import { TDocument } from '../../Features/LTDDocuments/@types';
import { RouteComponentProps, withRouter } from 'react-router';
import { TState } from '../../RootReducer';
import LeftNav from '../LTD/LeftNav';
import SCCLeftNav from '../SCC/LeftNav';
import CLDLeftNav from '../CLD/LeftNav';
import classNames from 'classnames';
import { fade } from '../../../node_modules/@material-ui/core/styles/colorManipulator';
// import { RouteComponentProps } from 'react-router';
import { HISTORY } from '../RootLayout';
import LegalFinding from './LegalFinding';
import { TCLDDocument } from '../../Features/CLDDocuments/@types';
import { OCLDDocumentTree } from '../../Features/CLDDocumentTree/redux-config';
import CLDMetadata from './CLDMetadata';
import DecisionMetadata from './DecisionMetadata';
import { ODocumentTree } from '../../Features/DocumentsTree/redux-config';
import { DOCUMENT_BASE_URL, SCC_DOCUMENT_BASE_URL } from '../../Features/LTDDocuments/redux-config';
import Agreement from './Agreement';
import PDFObject from 'pdfobject';
import { CLD_DOCUMENT_BASE_URL } from '../../Features/CLDDocuments/redux-config';
import LTDHeader from './LTDHeader';
import { OApp, TToastVariant } from '../../RootReducer/AppReducer';
import LTDMergeRecord from './LTDMergeRecord';
import MetaTag from '../../Components/MetaTag';
import {
    WithTranslationProps,
    withTranslations,
} from '../../Features/Translations/withTranslations';
import {
    DECISION_DOCUMENT_INFO_TAB_TEXT,
    DECISION_DOCUMENT_PDF_TAB_TEXT,
    LEGAL_FINDING_DOCUMENT_INFO_TAB_TEXT,
    LEGAL_FINDING_DOCUMENT_TAB_TEXT,
} from '../../Features/Translations/translationKeys.cld';
import {
    META_DATA_HEADER_INFO,
    META_DATA_HEADER_OPEN_PDF,
} from '../../Features/Translations/translationKeys.ltd';

interface Props
    extends WithStyles<typeof STYLES>,
    IStateProps,
    IDispatchProps,
    RouteComponentProps,
    WithTranslationProps { }

interface IStateProps {
    tree: any;
    currentDocument: TCurrentDocument;
    open: boolean;
    appConstants: any;
    ltdTree: any;
    sccTree: any;
}
interface IDispatchProps {
    closeDocument: Function;
    getDocumentDetail: Function;
    getSCCDocumentDetail: Function;
    getCLDDocumentDetail: Function;
    fetchCLDTree: Function;
    fetchSCCTree: Function;
    fetchLTDTree: Function;
    openSidebar: (component, title?: string) => void;
    closeSidebar: () => void;
    mergeRecord: (id, url) => Promise<void>;
    unMergeRecord: (id, url) => Promise<void>;
    openDocument: (id) => void;
}

class DetailPage extends Component<Props & WithTranslationProps> {
    state = {
        tab: 0,
        agreementDialogOpen: false,
        agreementAccepted: false,
        loading: false,
    };

    componentDidMount = () => {
        this.pageInit();
    };

    pageInit = () => {
        const cldDoc = /\/clddoc.*/i.test(window.location.pathname);
        const sccDoc = /\/sccdoc.*/i.test(window.location.pathname);
        const ltdDoc = /\/doc.*/i.test(window.location.pathname);
        const decision = /\/decision.*/i.test(window.location.pathname);
        const detailPageFor = ltdDoc ? 'doc' : sccDoc ? 'sccdoc' : cldDoc ? 'clddoc' : 'decision';
        const shouldOpen = cldDoc || ltdDoc || sccDoc || decision;

        if (!ltdDoc && _.isEmpty(this.props.tree)) this.props.fetchCLDTree();
        if (sccDoc && _.isEmpty(this.props.sccTree)) this.props.fetchSCCTree();
        if (ltdDoc && _.isEmpty(this.props.ltdTree)) this.props.fetchLTDTree();

        if (shouldOpen) {
            const url = _.split(this.props.location.pathname, '/');
            const index = _.indexOf(url, detailPageFor) + 1;
            const id = url[index];

            if (!_.isEmpty(id)) {
                if (_.get(this.props, 'currentDocument.documentId') !== id)
                    cldDoc
                        ? this.props.getCLDDocumentDetail(id)
                        : sccDoc
                            ? this.props.getSCCDocumentDetail(id, decision)
                            : this.props.getDocumentDetail(id, decision);
                if (!_.isEmpty(url[index + 1])) {
                    // const tab = url[index + 1] === 'pdf' ? 1 : 0
                    const tab =
                        url[index + 1] === 'pdf' ||
                            url[index + 1] === 'metadata'
                            ? 1
                            : 0;
                    this.setState({ tab });
                } else {
                    this.setState({ tab: 0 });
                }
            }
        }
    };

    componentDidUpdate = prevProps => {
        const cldDoc = /\/clddoc.*/i.test(window.location.pathname);
        const sccDoc = /\/sccdoc.*/i.test(window.location.pathname);
        const ltdDoc = /\/doc.*/i.test(window.location.pathname);
        const detailPageFor = ltdDoc ? 'doc' : sccDoc ? 'sccdoc' : cldDoc ? 'clddoc' : 'decision';

        const url = _.split(this.props.location.pathname, '/');
        const index = _.indexOf(url, detailPageFor) + 1;
        const id = url[index];
        const url2 = _.split(prevProps.location.pathname, '/');
        const index2 = _.indexOf(url2, detailPageFor) + 1;
        const id2 = url2[index2];

        const docSlug = _.get(this.props, 'currentDocument.document.slug');
        if (detailPageFor === 'doc' && docSlug && docSlug !== id) {
            return this.props.history.push('/doc/' + docSlug);
        }
        if (detailPageFor === 'sccdoc' && docSlug && docSlug !== id) {
            return this.props.history.push('/sccdoc/' + docSlug);
        }
        if (id !== id2) this.pageInit();

        if (this.props.location.pathname !== prevProps.location.pathname) {
            const url = _.split(this.props.location.pathname, '/');
            const index = _.indexOf(url, detailPageFor) + 1;
            if (!_.isEmpty(url[index + 1])) {
                const tab =
                    url[index + 1] === 'pdf' || url[index + 1] === 'metadata'
                        ? 1
                        : 0;
                this.setState({ tab });
            } else {
                this.setState({ tab: 0 });
            }
            // this.pageInit();

            this.setState({ agreementAccepted: false, loading: true });
        }
    };

    handlePDFClick = detailPageFor => {
        const { currentDocument } = this.props;
        const doc = _.get(currentDocument, 'document');
        if (!doc) return false;
        const slug = doc.slug || doc.id;
        const pdfUrl =
            detailPageFor === 'doc'
                ? `${DOCUMENT_BASE_URL}${slug}/pdf`
                : detailPageFor === 'sccdoc'
                ? `${SCC_DOCUMENT_BASE_URL}${slug}/pdf`
                : `${CLD_DOCUMENT_BASE_URL}${slug}/pdf`;
        if (PDFObject.supportsPDFs || detailPageFor === 'clddoc') {
            if (detailPageFor === 'clddoc')
                HISTORY.push(`/${detailPageFor}/${slug}/metadata`);
            else HISTORY.push(`/${detailPageFor}/${slug}/pdf`);
            return true;
        }
        const el = document.createElement('a');
        el.setAttribute('href', pdfUrl);
        el.setAttribute('target', '_blank');
        el.setAttribute(
            'style',
            'opacity:0;visibility:none;position:absolute;left:-9999'
        );
        el.click();
        el.remove();
        return false;
    };

    onTabChange = tab => {
        const cldDoc = /\/clddoc.*/i.test(window.location.pathname);
        const sccDoc = /\/sccdoc.*/i.test(window.location.pathname);
        const ltdDoc = /\/doc.*/i.test(window.location.pathname);

        const url = _.split(this.props.location.pathname, '/');
        const detailPageFor = ltdDoc ? 'doc' : sccDoc ? 'sccdoc' : cldDoc ? 'clddoc' : 'decision';

        const index = _.indexOf(url, detailPageFor) + 1;
        const id = url[index];

        let changeTab = true;
        console.log('tab changed', tab);
        if (tab === 1) {
            changeTab = this.handlePDFClick(detailPageFor);
            // (PDFObject.supportsPDFs) ? HISTORY.push(`/${detailPageFor}/${id}/pdf`) : window.open()
        } else HISTORY.push(`/${detailPageFor}/${id}/`);
        if (changeTab) this.setState({ tab });
    };

    close = () => {
        this.props.closeDocument();
    };

    printPage = (e: any) => window.print();

    onAgreeAgreement = () => {
        this.setState({ agreementAccepted: true });
    };

    onAgreeDownload = () => {
        this.setState({ agreementAccepted: true });
        this.toggleAgreementDialog();
        const pdfUrl = `${DOCUMENT_BASE_URL}${_.get(
            this.props.currentDocument,
            'document.slug'
        )}/pdf`;
        window.open(pdfUrl, '_blank');
    };

    onCancelAgreement = () => {
        this.toggleAgreementDialog();
    };

    toggleAgreementDialog = () =>
        this.setState({ agreementDialogOpen: !this.state.agreementDialogOpen });

    onDownloadClick = () => {
        const sccDoc = /\/sccdoc.*/i.test(window.location.pathname);
        if (!this.state.agreementAccepted) {
            this.toggleAgreementDialog();
            return;
        }

        const pdfUrl = sccDoc
            ? `${SCC_DOCUMENT_BASE_URL}${_.get(this.props.currentDocument, 'document.slug')}/pdf`
            : `${DOCUMENT_BASE_URL}${_.get(this.props.currentDocument, 'document.slug')}/pdf`;
        window.open(pdfUrl, '_blank');
    };

    handleMergeRecords = async (purl: string) => {
        const { currentDocument } = this.props;
        const doc = _.get(currentDocument, 'document');
        if (doc) {
            await this.props.mergeRecord(doc.id, purl);
        }
    };
    handleUnMergeRecords = async (purl: string) => {
        const { currentDocument } = this.props;
        const doc = _.get(currentDocument, 'document');
        if (doc) {
            await this.props.unMergeRecord(doc.id, purl);
        }
    };
    onMergeRecord = () => {
        const title = `Merge Records to: ${_.get(
            this.props,
            'currentDocument.document.title'
        )}`;
        this.props.openSidebar(
            <LTDMergeRecord
                // proxySlugs={_.get(this.props, 'currentDocument.document.proxySlugs') || []}
                onUnmerge={this.handleUnMergeRecords}
                onMerge={this.handleMergeRecords}
                title={title}
                onClose={this.props.closeSidebar}
            />
        );
    };

    render() {
        const { classes, currentDocument, open, getTranslation } = this.props;
        const {
            tab,
            agreementDialogOpen = false,
            agreementAccepted,
        } = this.state;
        const document = _.get(currentDocument, 'document');

        if (!document) return <LinearProgress color='primary' />;

        const pdfUrl = document
            ? `${DOCUMENT_BASE_URL}${document.slug}/pdf`
            : '';
        const url = _.get(document, 'orignalPdfURL') || '';

        const cldDoc = /\/clddoc.*/i.test(window.location.pathname);
        const sccDoc = /\/sccdoc.*/i.test(window.location.pathname);
        const ltdDoc = /\/doc.*/i.test(window.location.pathname);
        const decision = /\/decision.*/i.test(window.location.pathname);

        const shouldOpen = cldDoc || ltdDoc || sccDoc || decision;

        // if (!open || !shouldOpen)
        // return <div />

        let style: React.CSSProperties = {};

        if (!open || !shouldOpen) style = { left: 2000, opacity: 1 };
        else style = {};

        const requireDownloadPermission =
            _.get(document, 'requireDownloadPermission') || false;
        const copyRightAuthorisation =
            _.get(document, 'copyRightAuthorisation') || false;

        const tabHeadings = [
            getTranslation(META_DATA_HEADER_INFO),
            getTranslation(META_DATA_HEADER_OPEN_PDF),
        ];

        return (
            <Fragment>
                <div className={classNames(classes.root, 'print-hidden')}>
                    <div className={classes.leftNav}>
                        {ltdDoc ? <LeftNav /> : sccDoc ? <SCCLeftNav /> : <CLDLeftNav />}
                    </div>
                    <div className={classes.right} style={style}>
                        {decision && (
                            <Fragment>
                                <MetaTag
                                    title={document ? _.get(document, 'title') : undefined}
                                    ogDescription={document ? _.get(document, 'title') : undefined}
                                />
                                <div style={{ position: 'relative' }}>
                                    <Header
                                        currentDocument={currentDocument}
                                        tabs={[
                                            getTranslation(DECISION_DOCUMENT_INFO_TAB_TEXT),
                                            getTranslation(DECISION_DOCUMENT_PDF_TAB_TEXT),
                                        ]}
                                        tabValue={this.state.tab}
                                        onTabChange={this.onTabChange}
                                    />
                                </div>
                                <div className={classes.content}>
                                    {tab === 0 && <DecisionMetadata data={document as TDocument} />}
                                    {tab === 1 && <PdfContent url={pdfUrl} documentUrl={url} />}
                                </div>
                            </Fragment>
                        )}
                        {ltdDoc && (
                            <Fragment>
                                <MetaTag
                                    title={document ? _.get(document, 'title') : undefined}
                                    ogDescription={document ? _.get(document, 'title') : undefined}
                                />
                                <div style={{ position: 'relative' }}>
                                    <LTDHeader
                                        onMergeRecord={this.onMergeRecord}
                                        onDownload={this.onDownloadClick}
                                        currentDocument={currentDocument}
                                        tabs={tabHeadings}
                                        tabValue={this.state.tab}
                                        onTabChange={this.onTabChange}
                                    />
                                </div>
                                <div className={classes.ltdContent}>
                                    {tab === 1 ? (
                                        !copyRightAuthorisation ? (
                                            <ResourceNotAvailable classes={classes} />
                                        ) : requireDownloadPermission && !agreementAccepted ? (
                                            <Agreement
                                                classes={{
                                                    root: classes.agreementContainer,
                                                }}
                                                onAgree={this.onAgreeAgreement}
                                            />
                                        ) : (
                                            <PdfContent url={pdfUrl} documentUrl={url} />
                                        )
                                    ) : null}
                                    {tab === 0 ? <LTDMetadata data={document as TDocument} /> : null}
                                </div>
                            </Fragment>
                        )}

                        {cldDoc && (
                            <Fragment>
                                <MetaTag
                                    title={document ? _.get(document, 'Title') : undefined}
                                    ogDescription={document ? _.get(document, 'Title') : undefined}
                                />
                                <div style={{ position: 'relative' }}>
                                    <Header
                                        currentDocument={currentDocument}
                                        tabs={[
                                            getTranslation(LEGAL_FINDING_DOCUMENT_TAB_TEXT),
                                            getTranslation(LEGAL_FINDING_DOCUMENT_INFO_TAB_TEXT),
                                        ]}
                                        tabValue={this.state.tab}
                                        onTabChange={this.onTabChange}
                                    />
                                </div>
                                <div className={classes.content}>
                                    {tab === 0 && <LegalFinding data={document as TCLDDocument} />}
                                    {tab === 1 && <CLDMetadata data={document as TCLDDocument} />}
                                </div>
                            </Fragment>
                        )}

                        {sccDoc && (
                            <Fragment>
                                <MetaTag
                                    title={document ? _.get(document, 'title') : undefined}
                                    ogDescription={document ? _.get(document, 'title') : undefined}
                                />
                                <div style={{ position: 'relative' }}>
                                    <LTDHeader
                                        onMergeRecord={this.onMergeRecord}
                                        onDownload={this.onDownloadClick}
                                        currentDocument={currentDocument}
                                        tabs={tabHeadings}
                                        tabValue={this.state.tab}
                                        onTabChange={this.onTabChange}
                                    />
                                </div>
                                <div className={classes.ltdContent}>
                                    {tab === 1 ? (
                                        !copyRightAuthorisation ? (
                                            <ResourceNotAvailable classes={classes} />
                                        ) : requireDownloadPermission && !agreementAccepted ? (
                                            <Agreement
                                                classes={{
                                                    root: classes.agreementContainer,
                                                }}
                                                onAgree={this.onAgreeAgreement}
                                            />
                                        ) : (
                                            <PdfContent url={pdfUrl} documentUrl={url} />
                                        )
                                    ) : null}
                                    {tab === 0 ? <LTDMetadata data={document as TDocument} variant="scc" /> : null}
                                </div>
                            </Fragment>
                        )}

                    </div>
                    {/* {
                        cldDoc &&
                        <Button variant="flat" onClick={this.printPage} className={classes.printPageButton}>
                            Print <i className="material-icons">print</i>
                        </Button>
                    } */}
                </div>

                <Dialog open={agreementDialogOpen} PaperProps={{ className: classes.dialogRoot }}>
                    <Fab size='small' className={classes.dialogCloseBtn} onClick={this.toggleAgreementDialog}>
                        <i className='material-icons'>close</i>
                    </Fab>
                    <Agreement onAgree={this.onAgreeDownload} onCancel={this.onCancelAgreement} />
                </Dialog>

                <div id='print-content'>
                    {cldDoc && (
                        <>
                            <LegalFinding data={document as TCLDDocument} />
                            <CLDMetadata data={document as TCLDDocument} />
                        </>
                    )}
                </div>
            </Fragment>
        );
    }
}

function ResourceNotAvailable({ classes }) {
    return (
        <div className={classes.resourceNotAvailable}>
            <Typography variant='h4'>Resource not available</Typography>
        </div>
    );
}

const mapStateToProps = state => ({
    ..._.pick(state.DetailPage, ['open', 'currentDocument']),
    ..._.pick(state.CLDDocumentTree, ['tree']),
    ltdTree: _.get(state.DocumentTree, 'tree'),
    ..._.pick(state.App, ['appConstants']),
});

const mapDispatchToProps = dispatch => ({
    fetchLTDTree: () => dispatch(ODocumentTree.fetchBaseLevelNodes()),
    fetchSCCTree: () => dispatch(ODocumentTree.fetchBaseLevelNodes(false, 'scc')),
    closeDocument: () => dispatch(ODetail.closeDocument()),
    getDocumentDetail: (id, includeLegalFindings) => dispatch(ODetail.openDocument(id, 'LTD', includeLegalFindings)),
    getSCCDocumentDetail: (id, includeLegalFindings) => dispatch(ODetail.openDocument(id, 'SCC', includeLegalFindings)),
    getCLDDocumentDetail: id => dispatch(ODetail.openDocument(id, 'CLD')),
    fetchCLDTree: () => dispatch(OCLDDocumentTree.fetchBaseLevelNodes()),
    openSidebar: (component, title) => dispatch(OApp.openSidebar(component, title)),
    closeSidebar: () => dispatch(OApp.closeSidebar()),
    mergeRecord: (id, url) => dispatch(ODetail.mergeLTDRecords(id, url)),
    unMergeRecord: (id, url) => dispatch(ODetail.unMergeLTDRecords(id, url)),
    openDocument: id => dispatch(ODetail.openDocument(id)),
});

const STYLES = theme =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            height: `calc(100vh - 50px)`,
        },

        printPageButton: {
            position: 'absolute',
            top: 120,
            right: 18,
            '& i': {
                color: Theme.Colors.Primary.main,
            },
        },
        leftNav: {
            background: 'white',
            borderRadius: 0,
            zIndex: 1,
            display: 'flex',
            height: 'calc(100vh - 50px)',
            // overflow: 'auto',
            boxShadow: `2px 2px 3px ${fade(Theme.Colors.Grayscale.Black, 0.1)}`,
            flexDirection: 'column',
            minWidth: 360,
            width: 360,
            [theme.breakpoints.down('sm')]: {
                display: 'none',
                minWidth: 0,
                width: 0,
            },
        },

        right: {
            position: 'absolute',
            transition: '330ms ease-out',
            left: 360,
            top: 50,
            bottom: 0,
            boxShadow: 'none',
            zIndex: 0,
            right: 0,
            background: Theme.Colors.Grayscale.Grey5,
            overflow: 'auto',
            [theme.breakpoints.down('sm')]: {
                left: 0,
                // right: 0,
            },
        },
        backdrop: {
            position: 'absolute',
        },
        content: {
            flex: 1,
            paddingTop: 50,
            background: Theme.Colors.Grayscale.Grey5,
        },
        ltdContent: {
            flex: 1,
            paddingTop: 100,
            background: Theme.Colors.Grayscale.Grey5,
        },
        dialogRoot: {
            padding: 20,
        },
        dialogCloseBtn: {
            position: 'absolute',
            top: 6,
            right: 8,
            background: Theme.Colors.Grayscale.White,
            boxShadow: 'none',
        },
        agreementContainer: {
            background: Theme.Colors.Grayscale.White,
            marginTop: 50,
            padding: 20,
            borderRadius: 4,
        },
        resourceNotAvailable: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: 100,
        },
    });

export default withTranslations(
    withTranslations(
        withRouter(
            connect(
                mapStateToProps,
                mapDispatchToProps
            )(withStyles(STYLES)(DetailPage))
        )
    )
);
